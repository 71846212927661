import React, { useEffect, useState } from 'react'
import { Select, Table, message, Progress } from 'antd'
import {
  cleanObject,
  dateFormatHandler,
  permissionsHandler,
  _subscriptionsSutructureData,
} from '../../../functions'
import { strapiURL } from '../../../config'
import axios from 'axios'
import cookie from 'react-cookies'
import { navigate } from 'gatsby'
import { emailFrequency } from '../../../data'
import { Link } from 'gatsby'
import EmployerSaveSearchModal from '../../Modals/employerSaveSearch'
import CreditDetail from '../../creditDetail'
import { useSelector } from 'react-redux'
import JobTable from '../jobTable'

const EmployerDashboardMain = ({ userProfile, user, userRole }) => {
  const [loading, setLoading] = useState(true)
  const [editItem, setEditItem] = useState(null)
  const [stats, setStats] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [searches, setSearches] = useState([])
  const [deleteJobId, setDeleteJobId] = useState(null)
  const [jobs, setJobs] = useState([])
  const { Option } = Select
  const storeProducts = useSelector(state => state.user.products)
  let products = permissionsHandler(storeProducts, user?.role?.id)

  const profileLoadHandler = () => {
    setLoading(true)
    axios
      .get(strapiURL + `/employers/${userProfile?.id}/stats`)
      .then(response => {
        setStats(response.data)
        setJobs(response.data.activeJobs || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        message.error('Something went wrong.')
      })
  }

  const loadUserSearches = () => {
    setLoading(true)
    let id = userProfile?.id
    let currentUser = cookie.load('user')
    if (currentUser) {
      id = currentUser.user.id
    }

    axios
      .get(strapiURL + `/employer-searches/employer/${id}`)
      .then(res => {
        if (res?.data?.length) {
          let _data = res.data
          _data.forEach(item => {
            item.key = item.id
          })
          _data = _data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          setSearches(_data || [])
        }

        setLoading(false)
      })
      .catch(err => {
        message.error('Something went wrong')
        setLoading(false)
      })
  }

  useEffect(() => {
    if (userProfile) {
      profileLoadHandler()
      loadUserSearches()
    }
  }, [userProfile])

  const singleRecord = record => {
    let runningSearch = {
      ...record,
    }
    navigate('/employer-dashboard/resume/search', {
      state: {
        runningSearch,
      },
    })
  }

  const archiveJob = record => {
    setDeleteJobId(record.id)
    let data = {
      archived_date: new Date(),
      employer: record?.employer,
    }
    data = cleanObject(data)
    axios
      .put(strapiURL + `/jobs/${record.id}`, data)
      .then(res => {
        message.success('Job expired successfully')
        setDeleteJobId(null)
        profileLoadHandler()
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
        setDeleteJobId(null)
      })
  }

  const deleteSearch = searchId => {
    axios
      .delete(strapiURL + `/employer-searches/${searchId}`)
      .then(res => {
        let _copyData = [...searches]
        _copyData = _copyData.filter(item => item.id !== searchId)
        setSearches(_copyData)
        message.success('Search has been deleted')
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
      })
  }

  const updateSearch = (searchId, value) => {
    axios
      .put(strapiURL + `/employer-searches/${searchId}`, {
        frequency: value,
      })
      .then(res => {
        message.success('Search updated')
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
      })
  }

  const resumeCols = [
    {
      title: 'Search Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return (
          <p className="text-xs font-bold font-sans mb-0">
            {record?.name || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record, index) => {
        return (
          <p className="text-last text-location leading-0 ml-1 mb-0">
            {record?.keyword || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',

      render: (text, record, index) => {
        return (
          <Select
            onChange={val => updateSearch(record.id, val)}
            className="font-sans text-last text-jobTag w-[6rem]"
            defaultValue={record.frequency}
          >
            {emailFrequency.map((freq, index) => {
              return (
                <Option
                  className="font-sans text-last text-jobTag"
                  value={freq}
                  key={index}
                >
                  {freq}
                </Option>
              )
            })}
          </Select>
        )
      },
    },

    {
      title: 'Created on',
      dataIndex: 'published_at',
      key: 'published_at',
      render: (text, record, index) => {
        return (
          <p className="text-last text-location leading-0 ml-1 mb-0">
            {dateFormatHandler(record?.created_at) || 'Not Specified'}
          </p>
        )
      },
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => {
                setEditItem({
                  ...record,
                  location: record.location
                    ? record.location?.placePredict
                    : undefined,
                })
                setModalOpen(true)
              }}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Edit
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <div
              onClick={() => {
                singleRecord(record)
              }}
            >
              <p className="font-sans text-last text-merlot mb-0 cursor-pointer">
                Run
              </p>
            </div>
            <div className="h-4 w-px bg-merlot mx-2" />
            <p
              onClick={() => deleteSearch(record.id)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Delete
            </p>
          </div>
        )
      },
    },
  ]

  return !loading ? (
    <>
      {/* Main Cards */}
      <div className="flex flex-col lg:flex-row gap-6 xl:gap-8 mt-6 lg:mt-0">
        <div className="dashboardCard flex-1 bg-themePink rounded-md p-6 flex items-center justify-between">
          <div className="flex-1">
            <p className="text-[#6E1F36] font-bold text-lg mb-0.5 font-sans">
              Active Jobs
            </p>
            <p className="text-black font-sans text-last mb-0">
              Total Active jobs Today
            </p>
          </div>
          <Progress
            type="circle"
            percent={stats?.activeJobsCount}
            strokeColor="#6E1F36"
            strokeWidth="10"
            strokeLinecap="square"
            trailColor="white"
            className="text-[#6E1F36] dashboard-progress"
            style={{ color: '#6E1F36' }}
            format={percent => `${stats?.activeJobsCount || 0}`}
          />
        </div>
        <div className="dashboardCard flex-1 bg-[#E9F5FA] rounded-md p-6 flex items-center justify-between">
          <div className="flex-1 mr-3">
            <p className="text-[#00567C] font-bold text-lg mb-0.5 font-sans">
              Total Applications
            </p>
            <p className="text-black font-sans text-last mb-0">
              Total Applications Today
            </p>
          </div>
          <Progress
            type="circle"
            percent={stats?.applicationCount}
            strokeColor="#00567C"
            strokeWidth="10"
            strokeLinecap="square"
            trailColor="white"
            className="text-[#00567C] dashboard-progress"
            style={{ color: '#00567C' }}
            format={percent => `${stats?.applicationCount || 0}`}
          />
        </div>
        <div className="dashboardCard flex-1 bg-[#FFF8E9] rounded-md p-6 flex items-center justify-between">
          <div className="flex-1">
            <p className="text-[#756F63] font-bold text-lg mb-0.5 font-sans">
              Job Views
            </p>
            <p className="text-black font-sans text-last mb-0">
              Total Job Views Today
            </p>
          </div>
          <Progress
            type="circle"
            percent={stats?.views}
            strokeColor="#756F63"
            strokeWidth="10"
            strokeLinecap="square"
            trailColor="white"
            className="text-dashboardCard dashboard-progress"
            style={{ color: '#756F63' }}
            format={percent => `${stats?.views || 0}`}
          />
        </div>
      </div>
      <CreditDetail userProfile={userProfile} user={user} />
      {/* Recent Jobs */}
      <div className="rounded-lg bg-white shadow-profileCard mt-8">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Recent Jobs
            </h1>
          </div>
          <Link to="/employer-dashboard/jobs/manage/">
            <button className="bg-voyage py-2 px-3 font-sans text-white text-last font-bold rounded mr-2">
              View all
            </button>
          </Link>
        </div>
        <JobTable
          user={user}
          userProfile={userProfile}
          products={products}
          hasPagination={false}
          jobs={jobs}
          setJobs={setJobs}
          archiveJob={archiveJob}
          deleteJobId={deleteJobId}
          scroll={{ x: 'md:mx-content' }}
          pageSize={10}
        />
      </div>
      {/* Resume Alerts */}
      <div className="rounded-lg bg-white shadow-profileCard mt-8">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Resume Alerts
            </h1>
          </div>
          <Link to="/employer-dashboard/resume/search/">
            <button className="bg-voyage py-2 px-3 font-sans text-white text-last font-bold rounded mr-2">
              New Search
            </button>
          </Link>
        </div>
        <Table
          className="hide-table-header-background hide-table-header-cols-border"
          scroll={{ x: 'md:mx-content' }}
          columns={resumeCols}
          dataSource={searches || []}
          pagination={false}
          bordered={false}
        />
      </div>
      {/* Employer profile */}
      <div className="bg-white shadow-profileCard w-full rounded-lg mt-8">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-profile.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Company Profile
            </h1>
          </div>
          <Link to="/employer-dashboard/company-profile">
            <button className="bg-voyage py-2 px-3 font-sans text-white text-last font-bold rounded mr-2">
              Edit
            </button>
          </Link>
        </div>
        <div className="p-4 overflow-x-scroll md:overflow-x-auto">
          <div className="flex items-center">
            <div
              className="h-16 w-16 bg-gray-100 bg-cover bg-no-repeat relative cursor-pointer overflow-hidden cursor-pointer"
              style={{
                backgroundImage: `url(${userProfile?.image?.url || ''})`,
              }}
            ></div>
            <div className="ml-4">
              <p className="font-sans text-sm font-semibold mb-0.5">
                {userProfile?.name}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <p className="font-sans text-xs mb-2 font-bold">About</p>
            {userProfile?.description ? (
              <div
                className="job-desc-area employer-detail-area"
                dangerouslySetInnerHTML={{
                  __html: userProfile?.description,
                }}
              />
            ) : (
              <p>Not Specified</p>
            )}
            <div className="flex mt-4 gap-4 lg:gap-8">
              <div className="flex-1">
                <p className="font-sans text-xs mb-2 font-bold">Address</p>
                <p className="font-sans text-xs font-semibold mb-0">
                  {userProfile?.location?.location?.formattedAddress ||
                    'Not Specified'}
                </p>
              </div>
              <div className="flex-1">
                <p className="font-sans text-xs mb-2 font-bold">Website</p>
                {userProfile?.website ? (
                  <a
                    target="_blank"
                    href={userProfile?.website}
                    className="font-sans text-xs font-semibold mb-0 text-black underline"
                  >
                    {userProfile?.website}
                  </a>
                ) : (
                  <p className="font-sans text-xs font-semibold mb-0 text-black">
                    Not Specified
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployerSaveSearchModal
        editItem={editItem}
        setEditItem={setEditItem}
        userProfile={userProfile}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentData={searches}
        setCurrentData={setSearches}
      />
    </>
  ) : (
    <div
      className="w-full min-h-900px bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(/loaders/employer-dashboard-loader.png)` }}
    />
  )
}
export default EmployerDashboardMain
