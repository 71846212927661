import React from 'react'
import Layout from '../../components/employerDashboard/layout'
import EmployerDashboardMain from '../../components/employerDashboard/main'
const EmployerDashboard = ({ location }) => {
  return (
    <Layout title="Dashboard | MedReps.com" location={location}>
      <EmployerDashboardMain />
    </Layout>
  )
}
export default EmployerDashboard
